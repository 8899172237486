import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import { DataGrid } from '@mui/x-data-grid';
import './App.css'; // Import the CSS file

function App() {
  const [apiKeys, setApiKeys] = useState([]);
  const [selectedApiKey, setSelectedApiKey] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [error, setError] = useState('');
  const [successMessages, setSuccessMessages] = useState({});

  const handleAddDriver = useCallback(async (driver) => {
    console.log('handleAddDriver called');
    console.log('Selected driver ID:', driver.HOSDriverId);
    console.log('Selected API Key:', selectedApiKey);
    if (!driver.HOSDriverId) {
      setError('No driver selected');
      console.error('No driver selected');
      return;
    }
    try {
      const response = await fetch('https://fleetbooster.net/addDriverCopilot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          ApiKey: selectedApiKey,
          HOSDriverId: driver.HOSDriverId,
          LastName: driver.LastName,
          Name: driver.Name,
          LicenseNumber: driver.LicenseNumber,
          HOSUserName: driver.HOSUserName,
          HOSHomeBaseId: driver.HOSHomeBaseId
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setSuccessMessages((s) => ({ ...s, addDriver: 'Driver added successfully' }));
      console.log('Driver added successfully');
    } catch (error) {
      setError('Error adding driver: ' + error.message);
      console.error('Error adding driver: ' + error.message);
    }
  }, [selectedApiKey]);

  const handleEnableDriver = useCallback(async (driver) => {
    console.log('handleEnableDriver called');
    console.log('Selected driver ID:', driver.HOSDriverId);
    if (!driver.HOSDriverId) {
      setError('No driver selected');
      console.error('No driver selected');
      return;
    }
    try {
      const response = await fetch('https://fleetbooster.net/enableDriverCopilot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          ApiKey: selectedApiKey,
          LastName: driver.LastName,
          Name: driver.Name,
          HOSUserName: driver.HOSUserName
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setDrivers(drivers.map(d => d.HOSDriverId === driver.HOSDriverId ? { ...d, IsActive: 1 } : d));
      setSuccessMessages((s) => ({ ...s, enableDriver: 'Driver enabled successfully' }));
      console.log('Driver enabled successfully');
    } catch (error) {
      setError('Error enabling driver: ' + error.message);
      console.error('Error enabling driver: ' + error.message);
    }
  }, [selectedApiKey, drivers]);

  const handleDisableDriver = useCallback(async (driver) => {
    console.log('handleDisableDriver called');
    console.log('Selected driver ID:', driver.HOSDriverId);
    if (!driver.HOSDriverId) {
      setError('No driver selected');
      console.error('No driver selected');
      return;
    }
    try {
      const response = await fetch('https://fleetbooster.net/disableDriverCopilot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          ApiKey: selectedApiKey,
          LastName: driver.LastName,
          Name: driver.Name,
          HOSUserName: driver.HOSUserName
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setDrivers(drivers.map(d => d.HOSDriverId === driver.HOSDriverId ? { ...d, IsActive: 0 } : d));
      setSuccessMessages((s) => ({ ...s, disableDriver: 'Driver disabled successfully' }));
      console.log('Driver disabled successfully');
    } catch (error) {
      setError('Error disabling driver: ' + error.message);
      console.error('Error disabling driver: ' + error.message);
    }
  }, [selectedApiKey, drivers]);

  // Function to calculate column widths based on device width
  const calculateColumnWidths = useCallback(() => {
    const deviceWidth = window.innerWidth;
    const baseWidth = deviceWidth / 5; // Adjust the divisor based on the number of columns

    return [
      { field: 'HOSUserName', headerName: 'Username', width: baseWidth, editable: false },
      { field: 'LastName', headerName: 'Last Name', width: baseWidth, editable: false },
      { field: 'Name', headerName: 'Name', width: baseWidth, editable: false },
      {
        field: 'actions',
        headerName: 'Actions',
        width: baseWidth * 2, // Adjust the width for the actions column
        renderCell: (params) => (
          <div>
            <button onClick={() => handleAddDriver(params.row)}>Add</button>
            <button onClick={() => handleEnableDriver(params.row)}>Enable</button>
            <button onClick={() => handleDisableDriver(params.row)}>Disable</button>
          </div>
        )
      }
    ];
  }, [handleAddDriver, handleEnableDriver, handleDisableDriver]);

  const [columnsToShow, setColumnsToShow] = useState(calculateColumnWidths());

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const response = await fetch('https://fleetbooster.net/getEnabledCarriersCopilot');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setApiKeys(data);
      } catch (error) {
        setError('Error fetching API keys: ' + error.message);
      }
    };

    fetchApiKeys();

    // Update column widths on window resize
    const handleResize = () => {
      setColumnsToShow(calculateColumnWidths());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateColumnWidths]);

  useEffect(() => {
    const fetchDrivers = async () => {
      if (selectedApiKey) {
        try {
          const response = await fetch(`https://fleetbooster.net/getCarrierDriversCopilot?ApiKey=${selectedApiKey}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setDrivers(data);
        } catch (error) {
          setError('Error fetching drivers: ' + error.message);
        }
      }
    };

    fetchDrivers();
  }, [selectedApiKey]);

  return (
    <div>
      <header className="header">
        <h1>FleetBooster - Copilot Drivers Admin</h1>
      </header>
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="apiKeySelect">Select Carrier: </label>
        <select
          id="apiKeySelect"
          value={selectedApiKey}
          onChange={(e) => setSelectedApiKey(e.target.value)}
        >
          <option value="">Select an API Key</option>
          {apiKeys.map((apiKey) => (
            <option key={apiKey.ApiKey} value={apiKey.ApiKey}>
              {apiKey.ApiKey} - {apiKey.Name}
            </option>
          ))}
        </select>
      </div>
      <div className="data-grid-container">
        <div className="data-grid-theme" style={{ height: '80vh', width: '100%' }}>
          <DataGrid
            rows={drivers}
            columns={columnsToShow}
            pageSize={10}
            rowsPerPageOptions={[10]}
            pagination
            getRowId={(row) => row.HOSDriverId}
          />
        </div>
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {Object.keys(successMessages).map((key) => (
        <div key={key} style={{ color: 'green' }}>{successMessages[key]}</div>
      ))}
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

export default App;